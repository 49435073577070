<template>
  <div class="main" style="padding: 0">
    <!-- header -->
    <div class="header">
      <div class="imagebox">
        <img src="../assets/img/ccbst.png" alt="" />
      </div>
      <div @click="getindex" class="titleboxs">返回平台首页</div>
      <div class="titlebox">账号注册</div>
    </div>
    <div class="regbox">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <div class="title"><h4>账户设置:</h4></div>
        <el-form-item label="用户名" prop="Account">
          <el-input v-model="ruleForm.Account"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="Password">
          <el-input type="password" v-model="ruleForm.Password"></el-input>
        </el-form-item>
        <!-- <el-form-item label="确认密码" prop="Password">
    <el-input type="password" v-model="ruleForm.Password"></el-input>
  </el-form-item> -->
        <el-form-item> </el-form-item>
        <div class="title"><h4>手机绑定:</h4></div>
        <el-form-item label="手机号码" prop="Mobile">
          <el-input v-model="ruleForm.Mobile"></el-input>
        </el-form-item>
        <div class="imgcode" v-if="ruleForm.Mobile.length == 11">
          <slide-verify
            :l="42"
            :r="10"
            :w="310"
            :h="155"
            @success="onSuccess"
            @fail="onFail"
            @refresh="onRefresh"
            :slider-text="text"
          ></slide-verify>
          <div class="msg">{{ msg }}</div>
        </div>

        <el-form-item label="验证码" :required="true">
          <el-input v-model="Code"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100%"
            type="primary"
            @click="submitForm('ruleForm')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="footboxs">
      <p>
        Copyright © 2021陕西省西安浐灞国际会展有限责任公司. All Rights Reserved <br/>
      
      </p>
      <p> <a target="_blank" rel="noopener" href="http://beian.miit.gov.cn/">陕ICP备15005823号-2</a></p>
      <p>联系电话：029-83592139、029-83592506</p>
    </div>
  </div>
</template>


<script type="text/javascript" charset="utf-8" src="//g.alicdn.com/sd/ncpc/nc.js?t=2015052012"></script>
<script>
// 校验手机号
var validatorPhone = function (rule, value, callback) {
  if (value === "") {
    callback(new Error("手机号不能为空"));
  } else if (!/^1\d{10}$/.test(value)) {
    callback(new Error("手机号格式错误"));
  } else {
    callback();
  }
};
// 检验密码两次是否一致
var dobPhone = function () {};
export default {
  data() {
    return {
      msg: "",
      Code: "",
      text: "右滑发送验证码",
      ruleForm: {
        Account: "",
        Password: "",
        Mobile: "",
      },
      rules: {
        Account: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "2 到 20 个字符",
            trigger: "blur",
          },
        ],
        Password: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            min: 6,
            max: 18,
            message: "6 到 18 个字符",
            trigger: "blur",
          },
        ],

        Mobile: [
          { required: true, message: "请正确填写手机号", trigger: "change" },
          {
            type: "date",
            validator: validatorPhone,
            message: "请正确填写11位手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getindex() {
      this.$router.push("/");
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let item = that.ruleForm;
          item.Account = item.Account.trim();
          item.Password = item.Password.trim();
          let Code = that.Code;
          if (Code.trim().length != 4) {
            that.$message.error("请正确输入验证码");
            return;
          }
          let formData = new FormData();
          formData.append("Obj", JSON.stringify(item));
          formData.append("Code", Code);
          that.$post("/api/UserLogin/Register", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("注册成功");

              that.ruleForm = {};
              setTimeout(() => {
                that.$router.push({path:"/login",query: { type: '1' },});
              }, 1000);
            } else {
              that.$message.error(data.Msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onSuccess() {
      let that = this;
      let phone = that.ruleForm.Mobile;
      that
        .$post("/api/SendCode/SendCode", { Mobile: phone })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            that.msg = "验证码已发送至手机，请注意查看";
          }
        });
    },
    onFail() {
      this.msg = "请重新滑动";
    },
    onRefresh() {
      this.msg = "";
    },
  },
};
</script>


<style scoped  lang="scss">
.main {
  position: relative;
  overflow: hidden;
  .titlebox {
    float: left;
    height: 60px;
    line-height: 60px;
    font-weight: 800;
    font-size: 20px;
    margin-left: 200px;
  }
  .titleboxs {
    float: left;
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    margin-left: 50px;
    cursor: pointer;
  }
  .titleboxs:hover {
    color: dodgerblue;
  }
  .regbox {
    width: 500px;
    padding: 30px 100px;
    // border-bottom: 1px dashed #ddd;
    margin-left: 50%;
    transform: translateX(-50%);
    min-height: 600px;
    .title {
      height: 60px;
      line-height: 60px;
      text-align: left;
    }
    .imgcode {
      width: 100%;
      padding: 10px 100px;
      .msg {
        height: 60px;
        line-height: 60px;
      }
    }
  }
}

.footboxs {
  height: 200px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 50px;
  box-sizing: border-box;
  background-color: #fff;

  p {
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    text-align: center;
    a{
      color: #666;
    }
    a:hover{
      color: #409EFF;
    }
  }
}
.header {
  position: relative;
  padding-top: 10px;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;

  box-shadow: 0px 2px 5px #eee;
  z-index: 9999;
  /* border-bottom: 1px solid #eee; */

  .imagebox {
    float: left;
    height: 60px;
    width: 350px;
    margin-left: 30px;

    img {
      height: 60px;
    }
  }
}
@media (max-width: 500px) {
  .main {
    position: relative;
    overflow: hidden;
    width: 100%;
    .titlebox {
      float: left;
      height: 60px;
      line-height: 60px;
      font-weight: 800;
      font-size: 20px;
      margin-left: 50px;
    }
    .titleboxs {
      float: left;
      height: 60px;
      line-height: 60px;
      font-size: 12px;
      margin-left: 20px;
      cursor: pointer;
    }
    .titleboxs:hover {
      color: dodgerblue;
    }
    .regbox {
      width: 100%;
      padding: 0 20px;
      // border-bottom: 1px dashed #ddd;
      margin-left: 50%;
      transform: translateX(-50%);
      min-height: 400px;
      box-sizing: border-box;
      margin-top: 50px;
      .title {
        height: 60px;
        line-height: 60px;
      }
      .imgcode {
        width: 100%;
        padding: 10px 10px;
        .msg {
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }

  .footboxs {
    height: 150px;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    p {
      // height: 60px;
      line-height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #666;
      text-align: center;

      box-sizing: border-box;
      a{
        font-style: normal;
         color: #666;
      }
    }
  }
  .header {
    position: relative;
    padding-top: 10px;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background-color: #fff;

    box-shadow: 0px 2px 5px #eee;
    z-index: 9999;
    /* border-bottom: 1px solid #eee; */

    .imagebox {
      float: left;
      height: 60px;
      // width: 100%;
      max-width: 100%;
      margin-left: 20px;
      margin-top: 0;

      img {
        height: 40px;
        margin-top: 10px;
        width: auto;
        margin-left: 0;
      }
    }
  }
}
</style>